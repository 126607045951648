<div *ngIf="item">
  <div class="tw-relative tw-mb-4 tw-flex tw-h-[180px] 2xl:tw-mb-5">
    <img
      *ngIf="item?.listingServiceImageURI"
      [src]="item?.listingServiceImageURI | replace: '{style}' : 'x_large'"
      [ngClass]="{ greyScale: item?.declined || item?.isDeclined }"
      alt="Quote image"
      class="tw-h-full tw-w-full tw-rounded-lg tw-object-cover" />

    <div
      class="tw-absolute tw-bottom-[32px] tw-left-[12px] tw-rounded-[6px] tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-2 tw-py-1"
      *ngIf="item.showRequestInfo">
      <p class="tw-mb-0 tw-text-center tw-text-xs tw-font-semibold tw-text-slate-800">
        <span>{{ item?.eventType | stringToTitleCase }}</span>
        <span>&nbsp;•&nbsp;</span>
        <span>{{ item?.startDate | date: 'd MMM y' }}</span>
      </p>
    </div>
  </div>
  <div class="tw-px-2 2xl:tw-px-3">
    <div class="tw-flex tw-items-center tw-gap-1">
      <app-star-rating
        *ngIf="item.listingReviewRating"
        [reviewRating]="item.listingReviewRating"
        [reviewCount]="item?.listingReviewCount"
        [disabled]="item?.declined || item?.isDeclined"
        class="tw-mb-2.5 tw-flex tw-items-center lg:tw-mb-3">
      </app-star-rating>
      <!-- <div class="tw-mb-3 tw-h-5 tw-w-full" *ngIf="!item.listingReviewRating"></div> -->
      <span
        *ngIf="item.listingReviewCount && (item.listingBookingCount || item.bookingCount)"
        class="tw-mb-2.5 tw-text-sm tw-text-slate-600 lg:tw-mb-3"
        >•</span
      >
      <span class="tw-mb-2.5 tw-text-sm tw-text-slate-600 lg:tw-mb-3" *ngIf="item.listingBookingCount || item.bookingCount"
        >{{ item.listingBookingCount || item.bookingCount }} booking{{ (item.listingBookingCount || item.bookingCount) > 1 ? 's' : '' }}</span
      >
    </div>
  </div>

  <div class="'tw-px-2 2xl:tw-px-3'">
    <h4 [ngClass]="item.declined ? 'tw-text-slate-600' : 'tw-text-slate-800'" class="tw-max-w-full tw-text-lg tw-font-bold lg:tw-text-xl">
      {{ item?.listingTitle }}
    </h4>
  </div>

  <div class="tile-message tw-mt-2 tw-flex tw-flex-auto tw-flex-col tw-items-start tw-justify-stretch tw-gap-y-1 tw-p-3" *ngIf="item && item.message">
    <p class="tw-mb-0">{{ item?.message | trimText: 179 }}</p>
    <p class="tw-mb-0" *ngIf="item.message.length > 179"><a (click)="goToQuote()" class="gradient-anchor-tag">Read More</a></p>
  </div>
</div>
