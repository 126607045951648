import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileBaseComponent } from '../tile-base/tile-base.component';
import { SupplierInvitesService } from '../../../../../shared/services/supplier-invites/supplier-invites.service';
import { first } from 'rxjs';
import { NotificationModel, NotificationsService, NotificationTypes } from '../../../../../shared/services/notifications/notifications.service';
import { ListingAction } from '../../../../suppliers/components/supplier/supplier.component';
import { QuoteModel } from '../../../../../shared/models/quotes';
import { SharedModule } from '../../../../../shared/shared.module';
import { DatalayerService } from '../../../../../shared/services/datalayer/datalayer.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-suggested-tile',
  templateUrl: './suggested-tile.component.html',
  styleUrls: ['./suggested-tile.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, TileBaseComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SupplierInvitesService, NotificationsService]
})
export class SuggestedTileComponent implements OnChanges {
  @Input() suggestedInvite: QuoteModel;
  @Input() eventRequestUuid: string = '';
  @Input() invitesSent = 0;
  @Input() suggestedSupplierAction: ListingAction;
  loadingInvited: boolean = false;
  loadingSkipped: boolean = false;
  @Output() supplierInvited: EventEmitter<QuoteModel> = new EventEmitter<QuoteModel>();
  @Output() supplierSkipped: EventEmitter<QuoteModel> = new EventEmitter<QuoteModel>();

  constructor(
    private _supplierInvitesService: SupplierInvitesService,
    private _notificationsService: NotificationsService,
    private _dataLayer: DatalayerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.suggestedInvite) {
      this.suggestedInvite.listingServiceImageURI = this.suggestedInvite.images[0];
      this.suggestedInvite.listingReviewCount = this.suggestedInvite.reviewCount;
      this.suggestedInvite.listingReviewRating = this.suggestedInvite.reviewRating;
      this.suggestedInvite.listingTitle = this.suggestedInvite.title;
    }

    if (changes && changes.suggestedSupplierAction) {
      if (
        this.suggestedSupplierAction &&
        this.suggestedSupplierAction.listingUuid &&
        this.suggestedSupplierAction.listingUuid === this.suggestedInvite.uuid
      ) {
        if (this.suggestedSupplierAction.action === 'invite') {
          this.inviteSupplier(this.suggestedInvite.uuid);
        } else {
          this.skipSupplier(this.suggestedInvite.uuid);
        }
      }
    }
  }

  skipSupplier(listingUuid: string) {
    this.loadingSkipped = true;

    this._supplierInvitesService
      .skipSupplier(this.eventRequestUuid, {
        listingUuid: listingUuid
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.loadingSkipped = false;
          this.supplierSkipped.emit(this.suggestedInvite);
        },
        error: err => {
          this.loadingSkipped = false;
          const notification: NotificationModel = {
            type: NotificationTypes.ERROR,
            message: `Sorry, we couldn’t skip this invite. Please try again`,
            action: 'Close'
          };
          this._notificationsService.openSnackBar(notification);
          console.error(err?.toString() || JSON.stringify(err, null, 2));
        }
      });
  }

  inviteSupplier(listingUuid: string) {
    this.loadingInvited = true;

    this._supplierInvitesService
      .inviteSupplier(this.eventRequestUuid, {
        listingUuid: listingUuid
      })
      .pipe(first())
      .subscribe({
        next: () => {
          this.loadingInvited = false;
          this.suggestedInvite.invited = true;
          // +1 because we want to include this invite
          const invitesSent = this.invitesSent + 1;
          const notification: NotificationModel = {
            type: NotificationTypes.SUCCESS,
            message: `You have invited ${this.suggestedInvite.title} to send a quote. ${invitesSent} supplier${invitesSent > 1 ? 's' : ''} invited. You can invite ${environment.maxInvites - invitesSent} more.`,
            action: 'Close'
          };
          this._notificationsService.openSnackBar(notification);
          this.supplierInvited.emit(this.suggestedInvite);
          this._dataLayer.sendEvent('invite sent');
        },
        error: err => {
          this.loadingInvited = false;
          const notification: NotificationModel = {
            type: NotificationTypes.ERROR,
            message: `Sorry, we couldn’t send an invite to ${this.suggestedInvite.title}. Please try again`,
            action: 'Close'
          };
          this._notificationsService.openSnackBar(notification);
          console.error(err?.toString() || JSON.stringify(err, null, 2));
        }
      });
  }
}
