import { Component, CUSTOM_ELEMENTS_SCHEMA, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ServiceSelectorComponent } from '../service-selector/service-selector.component';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { appActions } from '../../../../store/actions/app.actions';
import { WindowService } from '../../../../shared/services/window/window.service';
import { IMainEventData } from '../../../../shared/models/events';
import { UpsellsComponent } from '../../../../features/event-request/components/event-request-overview/upsells/upsells.component';
import { MatDialog } from '@angular/material/dialog';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { filter, first } from 'rxjs';
import { EventRequestDialogComponent } from '../../../../shared/components/dialogs/event-request-dialog/event-request-dialog.component';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.scss'],
  standalone: true,
  imports: [ServiceSelectorComponent, CommonModule, UpsellsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewRequestComponent implements OnInit, OnDestroy {
  panelOpen = false;
  innerWidth: number;
  @Input() mainEventUuid = '';
  @Input() showSidebar = false;
  @Input() eventType = '';
  @Input() event: IMainEventData;

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService,
    @Inject('environment') private environment,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _store: Store,
    private _windowService: WindowService,
    public dialog: MatDialog,
    private _sso: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    if (!this._windowService.isMobile()) {
      setTimeout(() => {
        this._store.dispatch(appActions.ToggleShowingSidenav({ payload: this.showSidebar }));
      });
    }
    this.getData();
  }

  openForm({ formUuid = '', tid = '' }) {
    if (formUuid) {
      this._enhancedFormsLauncherService.launchForm({ formUuid, mainEventUuid: this.mainEventUuid });
    } else if (tid) {
      this.openV1Form(tid, this.mainEventUuid);
    } else {
      console.error('Unable to launch form - no name or formUuid specified');
    }
  }

  setPanelOpen(isOpen) {
    this.panelOpen = isOpen;
  }

  goBack(): void {
    const previousUrl = this._router.lastSuccessfulNavigation?.previousNavigation?.initialUrl;
    if (previousUrl) {
      this._router.navigateByUrl(previousUrl);
      return;
    }

    this._router.navigate(['..'], { relativeTo: this._activatedRoute });
  }

  /**
   * Launch a v1 form by formatting the service name in kebab case.
   *
   * @param name The name of the service.
   */
  private openV1Form(tid: string, mainEventUuid: string) {
    window.location.href = `${this.environment.webUrl}/request-quotes?form=open&mainEventUuid=${mainEventUuid}&field_event_suppliers=${tid}&source_path=${window.location.href}`;
  }

  /**
   * If either mainEventUuid or eventType are not passed in, then try to
   * parse them from the route.
   */
  private getData() {
    const { paramMap, queryParamMap } = this._activatedRoute.snapshot;
    if (!this.mainEventUuid) {
      this.mainEventUuid = paramMap.get('mainEventUuid') ?? queryParamMap.get('mainEventUuid');
    }
    if (!this.eventType) {
      this.eventType = queryParamMap.get('eventType');
    }
    if (queryParamMap.get('form') && queryParamMap.get('form') === 'open') {
      this.handlePrepopulatedCategory();
    }
  }

  handlePrepopulatedCategory() {
    const { queryParamMap } = this._activatedRoute.snapshot;
    const formUuid = queryParamMap.get('form_uuid');
    const tid = queryParamMap.get('field_event_suppliers');

    this._store
      .select(selectApp.getEvents)
      .pipe(filter(events => !!events))
      .pipe(first())
      .subscribe({
        next: events => {
          events?.length > 0
            ? this.openNewRequestDialog(events)
            : this.openForm({
                tid,
                formUuid
              });
        },
        error: err => {
          console.error('Could not retrieve events from store: ', err?.toString() || JSON.stringify(err, null, 2));
        }
      });
  }

  openNewRequestDialog(events: IMainEventData[]) {
    this.dialog.open(EventRequestDialogComponent, {
      width: '500px',
      height: this.innerWidth > 1023 ? 'auto' : '99vh',
      maxWidth: '100vw',
      hasBackdrop: true,
      backdropClass: 'dialog-backdrop-dark',
      panelClass: 'event-request-dialog',
      scrollStrategy: this._sso.noop(),
      data: {
        events
      }
    });
  }

  ngOnDestroy(): void {
    if (!this._windowService.isMobile()) {
      this._store.dispatch(appActions.ToggleShowingSidenav({ payload: true }));
    }
  }
}
