import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { map, Observable, Subscription } from 'rxjs';
import { QuoteService } from '../../services/quote.service';
import { QuoteModel } from '../../../../shared/models/quotes';
import { UtilityMessageConfig } from '../../../../shared/models/misc';
import { Store } from '@ngrx/store';
import { userQuery } from '../../../../store/selectors/user.selectors';
import { quotesTabConfig, TabConfig } from './quotes-tab.config';
import { QuoteTabsEnum } from './enums/quote-tabs.enum';
import { WindowService } from '../../../../shared/services/window/window.service';
import { ActivatedRoute, Router } from '@angular/router';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { appActions } from '../../../../store/actions/app.actions';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit, OnDestroy {
  currentRoute: string;
  emptyStateConfig: UtilityMessageConfig;
  hasNewQuotes$: Observable<boolean>;
  innerHeight: number;
  innerWidth: number;
  isError: boolean;
  isLoading: Observable<boolean>;
  isMobile = false;
  quotes: QuoteModel[] = null;
  quotesConfig: TabConfig[];
  QuoteTabEnum = QuoteTabsEnum;
  selectedIndex = 0;
  subQuotes$: Subscription;
  subscriptionList: Subscription = new Subscription();
  title: string;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.isMobile = this._windowService.isMobile();
  }

  constructor(
    private _quoteService: QuoteService,
    private _store: Store,
    private _windowService: WindowService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.isLoading = this._store.select(selectApp.getIsLoading);
    this.quotes = null;
    this.currentRoute = this.getRoute();
    this.tabSelected(0);
    this.isMobile = this._windowService.isMobile();
    this.hasNewQuotes$ = this._store.select(userQuery.getNewQuoteNotifications).pipe(map(notifications => notifications.length > 0));
  }

  getRoute(): string {
    const url: string = this._router.url.split('?')[0];
    const segments: string[] = url.split('/').filter(el => el !== '');
    return segments.length > 0 ? segments[0] : 'unknown';
  }

  tabSelected(index: number): void {
    this.quotesConfig = quotesTabConfig[this.currentRoute].tabs;
    if (['quotes', 'bookings'].includes(this.currentRoute)) {
      const { title, empty } = this.quotesConfig[index];
      this.getViewSource(title);
      this.setQuotesConfig(this.currentRoute);
      this.emptyStateConfig = empty;
    }
  }

  private setQuotesConfig(currentRoute: string) {
    if (['quotes', 'bookings'].includes(this.currentRoute)) {
      if (currentRoute === 'quotes') {
        this.title = 'My Quotes';
      } else if (currentRoute === 'bookings') {
        this.title = 'My Bookings';
      }
    }
  }

  getViewSource(title: string) {
    let viewSourceParam: string;
    const viewSource: string = this._activatedRoute.snapshot.queryParamMap.get('viewSource');
    const utmSource: string = this._activatedRoute.snapshot.queryParamMap.get('utm_source');
    const utmCampaign: string = this._activatedRoute.snapshot.queryParamMap.get('utm_campaign');
    const utmMedium: string = this._activatedRoute.snapshot.queryParamMap.get('utm_medium');

    if (viewSource) {
      viewSourceParam = viewSource;
    } else if (utmSource && utmCampaign && utmMedium) {
      viewSourceParam = `${utmSource}__${utmCampaign}__${utmMedium}`;
    } else {
      viewSourceParam = null;
    }
    this.getQuotes(title, viewSourceParam);
  }

  getQuotes(quoteStatus: string, viewSource: string) {
    this.quotes = null;
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.subQuotes$ = this._quoteService.getQuotes(quoteStatus, viewSource).subscribe({
      next: response => {
        this.emptyStateConfig = this.quotesConfig[0].empty;
        this.quotes = response.data.map(el => {
          el.showRequestInfo = true;
          return el;
        });
        this._store.dispatch(appActions.DecrementLoadingCount());
        this.isError = false;
      },
      error: err => {
        console.error(err?.toString() || JSON.stringify(err, null, 2));
        this._store.dispatch(appActions.DecrementLoadingCount());
        this.isError = true;
      }
    });
  }

  goToQuote(quote: QuoteModel): void {
    this._router.navigate(['/quote/', quote.uuid]);
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
