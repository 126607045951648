export enum EventRequestStatusEnum {
  ALL_QUOTES_DECLINED = 'ALL_QUOTES_DECLINED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  AWAITING_QUOTES = 'AWAITING_QUOTES',
  CLOSED_BEFORE_APPROVAL = 'CLOSED_BEFORE_APPROVAL',
  CLOSED_EXPIRED_NO_QUOTES = 'CLOSED_EXPIRED_NO_QUOTES',
  CLOSED_NO_MATCHES = 'CLOSED_NO_MATCHES',
  CLOSED_PASSED = 'CLOSED_PASSED',
  CLOSED_REASON_ALL_DECLINED_QUOTES = 'CLOSED_REASON_ALL_DECLINED_QUOTES',
  CLOSED_REASON_NO_QUOTES = 'CLOSED_REASON_NO_QUOTES',
  CLOSED_REASON_OPEN_QUOTES = 'CLOSED_REASON_OPEN_QUOTES',
  CLOSED_REASON_SUPPLIER_CHOSEN_NO_OPEN = 'CLOSED_REASON_SUPPLIER_CHOSEN_NO_OPEN',
  CLOSED_REASON_SUPPLIER_CHOSEN_OPEN = 'CLOSED_REASON_SUPPLIER_CHOSEN_OPEN',
  DENIED_DUPLICATE = 'DENIED_DUPLICATE',
  DENIED_EMAILS = 'DENIED_EMAILS',
  DENIED_EMAIL_BOUNCE = 'DENIED_EMAIL_BOUNCE',
  DENIED_FLOOD = 'DENIED_FLOOD',
  DENIED_MANUAL = 'DENIED_MANUAL',
  EXPIRED_ACTIVE_QUOTES = 'EXPIRED_ACTIVE_QUOTES', // Automatically closed after 7 days, event not passed, active quotes
  EXPIRED_ALL_DECLINED_QUOTES = 'EXPIRED_ALL_DECLINED_QUOTES', // Automatically closed after 7 days, event not passed, quotes all declined
  EXPIRED_SUPPLIER_CHOSEN = 'EXPIRED_SUPPLIER_CHOSEN', // Automatically closed after 7 days, event not passed, selected quote
  FIRST_QUOTE = 'FIRST_QUOTE',
  NEW_QUOTE = 'NEW_QUOTE',
  OPEN_NO_MATCHES = 'OPEN_NO_MATCHES',
  QUOTES_RECEIVED = 'QUOTES_RECEIVED',
  SUPPLIER_CHOSEN_NO_OPEN = 'SUPPLIER_CHOSEN_NO_OPEN',
  SUPPLIER_CHOSEN_OPEN = 'SUPPLIER_CHOSEN_OPEN',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  DRAFT = 'DRAFT',
  DRAFT_PASSED = 'DRAFT_PASSED'
}
