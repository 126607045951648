<app-empty-message *ngIf="isError" class="error tw-m-5" [config]="emptyMessageConfig"></app-empty-message>
<div class="center">
  <div class="container">
    <header *ngIf="(isLoading | async) === false && !isError">
      <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
      <div class="titles">
        <h3>{{ eventRequest?.primaryServiceTitle }}</h3>
        <h1>Request Details</h1>
      </div>
      @if (showEditRequest) {
        <ate-button-control (click)="openEditRequestForm()" class="tw-ml-auto tw-w-fit" [attr.color]="'secondary'">Edit Request</ate-button-control>
      }
    </header>
    <section class="row triple">
      <div>
        <p><b>Event Type</b></p>
        <p>{{ eventRequest?.event?.eventType | eventType }}</p>
      </div>
      <div>
        <p><b>Services Required</b></p>
        <p>
          {{ eventRequest?.requiredServices || eventRequest?.primaryServiceTitle }}
        </p>
      </div>
    </section>
    <section class="row triple">
      <div>
        <p><b>Event Date</b></p>
        <p>
          {{ eventStartDate }}
          <span *ngIf="eventRequest?.event?.flexibleDate">
            <span class="tw-text-red-600">*</span><br />
            <span class="tw-text-sm">* Is flexible on dates</span>
          </span>
        </p>
      </div>
      <div *ngIf="eventStartTime">
        <p><b>Start Time</b></p>
        <p>{{ eventStartTime }}</p>
      </div>
      <div *ngIf="eventDuration">
        <p><b>Duration required</b></p>
        <p>{{ eventDuration }}</p>
      </div>
    </section>
    <section class="row">
      <p><b>Event Location</b></p>
      <p>{{ eventRequest?.event?.geocode?.fullAddress }}</p>
      <div class="map">
        <ate-map [attr.api-key]="apiKey" [attr.lat]="eventRequest?.event?.geocode?.lat" [attr.lng]="eventRequest?.event?.geocode?.lng"></ate-map>
      </div>
    </section>
    <section class="row" *ngFor="let response of formResponses">
      <p>
        <b>{{ response.label }}</b>
      </p>
      <p *ngIf="!response.isArray">{{ response.value }}</p>
      <ul *ngIf="response.isArray">
        <li *ngFor="let listItem of response.value">{{ listItem }}</li>
      </ul>
    </section>
    <section class="row">
      <p>
        <b>Organiser's Notes</b>
      </p>
      <p>{{ eventDescription }}</p>
    </section>
  </div>
</div>
