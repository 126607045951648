<app-basic-header class="tw-sticky tw-top-0" (closeClick)="goToInvitesPage()"></app-basic-header>
<section class="tw-m-6 lg:tw-my-14">
  @if (!maxInvitesReached) {
    <header class="tw-mb-6 tw-flex tw-justify-center tw-gap-4 lg:tw-mb-10">
      <h1 class="tw-text-2xl tw-font-bold tw-text-slate-800 lg:tw-text-4xl">
        Invite 5 Suppliers for a <span class="tw-text-brand-midpurple">30%</span> quicker response
      </h1>
    </header>

    <!-- Info Bubble-->
    <div class="tw-m-auto tw-w-fit tw-rounded-lg tw-bg-blue-100 tw-px-4 tw-py-3 lg:tw-px-6 lg:tw-py-4">
      <p class="tw-m-0 tw-text-center tw-text-sm tw-text-slate-800 lg:tw-text-base">
        We’ve found some great suppliers for your event. Invite them and we’ll let them know you’re interested in hearing from them.
      </p>
    </div>
    <div class="triangle tw-m-auto tw-h-3 tw-w-5 tw-bg-blue-100"></div>

    <!-- Progress breadcrumbs-->
    <section class="tw-mb-6 tw-ml-auto tw-mr-auto tw-mt-3 tw-flex tw-max-w-[600px]">
      <div class="dashed-divider tw-relative tw-shrink tw-grow tw-basis-0">
        <ate-icon name="ate-check-circle" class="tw-m-auto tw-mb-3 tw-block tw-size-5 tw-shrink-0 tw-text-green-700 lg:tw-size-6"></ate-icon>
        <h3 class="tw-text-center tw-text-xs tw-font-semibold tw-text-green-800 lg:tw-text-base">Request Submitted</h3>
      </div>

      <div class="dashed-divider tw-relative tw-shrink tw-grow tw-basis-0">
        <div
          class="tw-m-auto tw-mb-3 tw-flex tw-size-5 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-gradient-to-r tw-from-brand-gradientfrom tw-to-brand-gradientto tw-text-xs tw-font-bold tw-text-white lg:tw-size-6 lg:tw-text-base">
          2
        </div>
        <h3
          class="tw-bg-gradient-to-r tw-from-brand-gradientfrom tw-to-brand-gradientto tw-bg-clip-text tw-text-center tw-text-xs tw-font-semibold tw-text-transparent lg:tw-text-base">
          Invite Suppliers
        </h3>
      </div>

      <div class="tw-shrink tw-grow tw-basis-0">
        <div
          class="tw-m-auto tw-mb-3 tw-flex tw-size-5 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-slate-200 tw-text-xs tw-font-bold tw-text-slate-600 lg:tw-size-6 lg:tw-text-base">
          3
        </div>
        <h3 class="tw-text-center tw-text-xs tw-font-semibold tw-text-slate-500 lg:tw-text-base">Get Quotes</h3>
      </div>
    </section>
    <a
      *ngIf="isMobile"
      [routerLink]="['/recommendations/', eventUuid, eventRequestUuid]"
      class="tw-mb-8 tw-block tw-text-center tw-text-sm tw-font-normal tw-text-slate-600 tw-underline"
      >Continue to Request</a
    >

    <div class="tw-m-auto tw-flex tw-max-w-[1app80px] tw-items-center tw-justify-between">
      <h2 class="tw-text-xl tw-font-bold tw-text-slate-800 lg:tw-text-3xl">Your matched suppliers</h2>
      <a
        *ngIf="!isMobile"
        [routerLink]="['/recommendations/', eventUuid, eventRequestUuid]"
        class="tw-block tw-text-center tw-text-sm tw-font-normal tw-text-slate-600 tw-underline"
        >Continue to Request</a
      >
    </div>

    <!-- invites list component -->

    <div class="tiles-container tw-pb-[97px] tw-pt-5 md:tw-pt-6">
      <div class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3">
        <app-suggested-tile
          *ngFor="let invite of invites; let i = index"
          [tabIndex]="i + 1"
          [eventRequestUuid]="eventRequestUuid"
          [suggestedInvite]="invite"
          [suggestedSupplierAction]="suggestedSupplierAction"
          [invitesSent]="invitesSent"
          (supplierSkipped)="getInvites()"
          (supplierInvited)="getInvites()"
          (click)="getPublicListing(invite, 'suggested')"></app-suggested-tile>
      </div>
      @if (canLoadMoreSuppliers) {
        <ate-button-control class="tw-ml-auto tw-mr-auto tw-mt-6 tw-rounded-lg lg:tw-flex lg:tw-w-fit" (click)="getInvites(true)" color="secondary">
          Show more suppliers
        </ate-button-control>
      }
    </div>
  } @else {
    <section class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4">
      <ate-icon class="tw-size-8 tw-text-green-700" name="check-circle-v2"></ate-icon>
      <h2 class="tw-text-xl tw-font-bold tw-text-slate-800 lg:tw-text-3xl">{{ invitesSent }} suppliers invited</h2>
      <p class="tw-text-center">You have invited the maximum number of suppliers you can invite.</p>
    </section>
  }
</section>
<div class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-border-0 tw-border-t tw-border-solid tw-border-slate-300 tw-bg-white tw-px-4 tw-py-3">
  <ate-button-control class="tw-ml-auto tw-rounded-lg lg:tw-m-auto lg:tw-flex lg:tw-w-fit" color="cta" (click)="goToInvitesPage()">
    {{ maxInvitesReached ? 'Next' : 'Skip' }}
  </ate-button-control>
</div>
