import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IMainEventData } from '../../models/events';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private _http: HttpClient,
    private router: Router
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEvents(eventState: string): Observable<any> {
    let params: HttpParams = new HttpParams();

    params = params.set('filter', eventState);

    return (
      this._http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(`${environment.apiUrl}/org/event/main-list`, {
          params: params,
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json'
          }
        })
        .pipe(catchError(err => this.handleError(err)))
    );
  }

  getEvent(eventUuid: string): Observable<IMainEventData> {
    return this._http
      .get<IMainEventData>(`${environment.apiUrl}/org/event/${eventUuid}/main`, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        }
      })
      .pipe(catchError(err => this.handleError(err)));
  }

  updateEvent(eventUuid: string, mainEventUuid: string) {
    return this._http
      .post(`${environment.apiUrl}/org/event/${eventUuid}/set-main/${mainEventUuid}`, {})
      .pipe(catchError(err => this.handleError(err)));
  }

  openEvent(eventUuid: string) {
    return this._http.post(`${environment.apiUrl}/org/event/${eventUuid}/open`, {}).pipe(catchError(err => this.handleError(err)));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', console.error(error.error?.toString() || JSON.stringify(error.error, null, 2)));
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: `,
        console.error(error.error?.toString() || JSON.stringify(error.error, null, 2))
      );
    }

    if (error.status >= 400 && error.status < 500 && error.status !== 401) {
      // this.router.navigate(['error']);
      return throwError(() => new Error('error'));
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
