<div
  data-cy="status-pill"
  [ngClass]="config?.backgroundColour"
  class="tw-flex tw-items-start tw-justify-start tw-gap-2.5 tw-rounded-lg tw-py-2 tw-pl-2.5 tw-pr-3">
  <ate-icon class="tw-size-5 tw-shrink-0 2xl:tw-size-6" [ngClass]="config?.iconColour" [name]="config?.iconName"></ate-icon>
  <div class="tw-flex tw-flex-col tw-gap-1">
    <h5 class="tw-text-sm tw-font-semibold tw-tracking-normal 2xl:tw-text-base" [ngClass]="config?.textColour">{{ config?.statusText }}</h5>
    <span class="tw-text-sm tw-text-slate-600" *ngIf="config?.secondaryText"
      ><span class="tw-font-semibold tw-text-slate-700" *ngIf="config?.secondaryLabel">{{ config.secondaryLabel }}</span
      >{{ config.secondaryText }}</span
    >
  </div>
  <div data-cy="right-side-text" class="tw-ml-auto tw-text-sm" *ngIf="config?.rightSideText && config?.showRightSideText">
    {{ config.rightSideText }}
  </div>
</div>
