/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { InvitesHeaderData, InvitesList, InvitesListing } from '../../models/invites-list.interface';
import { ISuccessResponse } from '../../models/success-response.interface';
import { UpdateInterestDto } from '../../models/update-interest.interface';

@Injectable()
export class SupplierInvitesService {
  private _inviteSuppliersSubject = new BehaviorSubject<boolean>(true);
  public inviteSuppliers$ = this._inviteSuppliersSubject.asObservable();

  private _invitesHeaderDataSubject = new BehaviorSubject<InvitesHeaderData>({});
  public invitesHeaderData$ = this._invitesHeaderDataSubject.asObservable();

  constructor(
    private _http: HttpClient,
    private router: Router
  ) {}

  getInvites(
    eventRequestUuid: string,
    limit = 30,
    page?: number
  ): Observable<{ totalInvited: number; listings: InvitesListing[]; count: number; total: number }> {
    return this._http
      .get<InvitesList>(`${environment.apiUrl}/org/invites/${eventRequestUuid}`, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        },
        params: {
          limit,
          ...(page && { page })
        }
      })
      .pipe(
        map(({ data = [], invited, count, total }) => ({
          count,
          total,
          totalInvited: invited?.length ?? 0,
          listings: data.map(item => ({
            ...item,
            invited: false
          }))
        })),
        catchError(err => this.handleError(err))
      );
  }

  inviteSupplier(eventRequestUuid: string, payload: any): Observable<ISuccessResponse> {
    return this._http
      .post<ISuccessResponse>(`${environment.apiUrl}/org/invites/${eventRequestUuid}`, payload, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        }
      })
      .pipe(catchError(err => this.handleError(err)));
  }

  skipSupplier(eventRequestUuid: string, payload: any): Observable<ISuccessResponse> {
    return this._http
      .post<ISuccessResponse>(`${environment.apiUrl}/org/invites/${eventRequestUuid}/skip`, payload, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        }
      })
      .pipe(catchError(err => this.handleError(err)));
  }

  declineInterest(eventRequestUuid: string, listingUuid: string) {
    const payload: UpdateInterestDto = {
      isDeclined: true,
      eventRequestUuid,
      listingUuid
    };

    return this._http.patch<ISuccessResponse>(`${environment.apiUrl}/org/interests/update`, payload, {
      headers: {
        'Content-Type': 'application/ate.organiser.v1+json'
      }
    });
  }

  // @TODO
  // abstract
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', console.error(error.error?.toString() || JSON.stringify(error.error, null, 2)));
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        console.error(error.error?.toString() || JSON.stringify(error.error, null, 2))
      );
    }

    // if 400 error issue with app, wrong version?
    if (error.status >= 400 && error.status < 500 && error.status !== 401) {
      this.router.navigate(['error']);
      return throwError(() => new Error('error'));
    }

    // Service offline, not issue with app version so allow try again
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  publishInviteStatus(inviteStatus: boolean) {
    this._inviteSuppliersSubject.next(inviteStatus);
  }

  publishInvitesHeaderData(invitesHeaderData: InvitesHeaderData) {
    this._invitesHeaderDataSubject.next(invitesHeaderData);
  }
}
