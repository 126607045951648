<div class="tw-flex tw-h-full tw-flex-col" [ngClass]="{ disabled: !!statusReason }">
  <div class="tw-mb-auto">
    <app-tile-base [item]="quote"></app-tile-base>
  </div>
  <section class="tw-mb-4 tw-mt-5 tw-flex tw-justify-between">
    <app-enhanced-status-pill class="tw-grow" [config]="statusPillConfig"></app-enhanced-status-pill>
  </section>
  <p *ngIf="statusReason">
    <span>REASON:</span>
    <span>{{ statusReason }}</span>
  </p>

  <button
    data-cy="view-quote-and-chat-button"
    (click)="goToQuote()"
    [ngClass]="isDeclined ? 'btn-secondary' : 'btn-outline'"
    class="btn-action btn-action-md">
    <span>View Quote and Chat</span>
  </button>
</div>
