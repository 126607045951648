import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { InformationBoxComponent } from '../information-box/information-box.component';
import { EventsService } from '../../services/events/events.service';
import { Router } from '@angular/router';
import { FlashMessageService } from '../../services/flash-message/flash-message.service';
import { FlashMessageType } from '../../enums/flash-message-type.enum';
import { EventRequestService } from '../../services/event-request/event-request.service';
import { EnhancedFormsLauncherService } from '../../services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { appActions } from '../../../store/actions/app.actions';
import { Store } from '@ngrx/store';
import { EnhancedFormClose } from '../../enums/enhanced-form-close.enum';
import { ServiceListService } from '../../../features/new-request/services/services-list/service-list.service';

@Component({
  selector: 'app-draft-request-information-box',
  templateUrl: './draft-request-information-box.component.html',
  standalone: true,
  imports: [CommonModule, InformationBoxComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DraftRequestInformationBoxComponent implements OnInit {
  _isLoading: boolean;
  isV3Form = false;

  @Input() eventUuid = '';
  @Input() eventRequestUuid = '';
  @Input() mainEventUuid = '';
  @Input() formUuid = '';

  constructor(
    private _router: Router,
    private _eventsService: EventsService,
    private _flashMessageService: FlashMessageService,
    private _eventRequestService: EventRequestService,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService,
    private _store: Store,
    private _serviceListService: ServiceListService
  ) {}

  ngOnInit(): void {
    if (this.formUuid) {
      this._serviceListService
        .isV3Form(this.formUuid)
        .pipe(first())
        .subscribe(result => {
          this.isV3Form = result;
        });
    }
  }

  // Needs to not happen on error
  openEditRequestForm() {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.getQuoteOnFormCloseNow();
    if (!this.eventUuid) {
      return;
    }

    this._eventRequestService
      .getRequestResponses(this.eventUuid)
      .pipe(first())
      .subscribe(
        ({ response, formUuid }) => {
          if (!response) {
            console.error('Could not load previous responses');
          }
          this._store.dispatch(appActions.DecrementLoadingCount());
          this._enhancedFormsLauncherService.launchForm({
            formUuid,
            eventUuid: this.eventUuid,
            mainEventUuid: this.mainEventUuid,
            model: response,
            formType: 'simplified-new-event',
            editMode: true
          });
        },
        (err: Error) => {
          console.error(err?.toString() || JSON.stringify(err, null, 2));
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      );
  }

  getQuotesNow() {
    this._isLoading = true;
    this._eventsService
      .openEvent(this.eventUuid)
      .pipe(first())
      .subscribe({
        next: () => {
          this._router.navigate(['/invites/', this.eventUuid, this.eventRequestUuid]);
          this._isLoading = false;
        },
        error: () => {
          this._isLoading = false;
          this._flashMessageService.show({
            flashMessageType: FlashMessageType.Error,
            message: `Failed to open event request: ${this.eventRequestUuid}`
          });
        }
      });
  }

  private getQuoteOnFormCloseNow() {
    this._enhancedFormsLauncherService
      .onFormClose()
      .pipe(first())
      .subscribe({
        next: response => {
          // // If the organiser clicked X to close the form then we should not submit.
          if (response?.detail === EnhancedFormClose.BAIL) {
            return;
          }
          this.getQuotesNow();
        },
        error: err => console.error(err?.toString() || JSON.stringify(err, null, 2))
      });
  }
}
