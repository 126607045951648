import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { WindowService } from '../../../../shared/services/window/window.service';
import { IMainEventData } from '../../../../shared/models/events';
import { Subscription } from 'rxjs';
import { EventRequestOverviewService } from '../../../../shared/services/event-request-overview/event-request-overview.service';
import { EventsService } from '../../../../shared/services/events/events.service';
import { Store } from '@ngrx/store';
import { appActions } from '../../../../store/actions/app.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { EmptyMessageActionTriggers, EmptyMessageConfig } from '../../../../shared/models/misc';
import { DOCUMENT } from '@angular/common';
import { EventRequestTypeEnum } from '../../../../shared/services/event-request/event-request-type.enum';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrl: './events-list.component.scss'
})
export class EventsListComponent implements OnInit, OnDestroy {
  activeTab: number = 0;
  createNewEventActive: boolean;
  events: IMainEventData[];
  eventState: string;
  emptyMessageConfig: EmptyMessageConfig;
  innerHeight: number;
  innerWidth: number;
  isMobile: boolean = false;
  loadingEvents: boolean;
  selectedIndex: number = 0;
  subEvents$: Subscription;
  subRoute$: Subscription;
  subscriptionList: Subscription = new Subscription();
  showMultipleEventsMessage: boolean;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.isMobile = this._windowService.isMobile();
  }

  constructor(
    private _windowService: WindowService,
    private _eventRequestOverviewService: EventRequestOverviewService,
    private _eventsService: EventsService,
    private _store: Store,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) readonly document: Document
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.getRoute();
    this.createNewEventActive = this.selectedIndex === 0;
  }

  get window(): Window {
    return this.document.defaultView;
  }

  getRoute() {
    this.subRoute$ = this._activatedRoute.paramMap.subscribe({
      next: response => {
        this.subscriptionList.add(this.subRoute$);
        const currentRoute: string = response.get('eventState') || 'upcoming';

        if (currentRoute === 'upcoming') {
          this.selectedIndex = 0;
        } else if (currentRoute === 'past') {
          this.selectedIndex = 1;
        }

        this.getEvents(currentRoute);
      },
      error: err => console.error(err?.toString() || JSON.stringify(err, null, 2))
    });
  }

  tabSelected(index: number): void {
    const eventState = index === 0 ? 'upcoming' : 'past';
    this.createNewEventActive = index === 0;
    this._router.navigateByUrl(`events/${eventState}`);
  }

  getEvents(eventState: string) {
    this.setEmptyState(eventState);
    this.loadingEvents = true;
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.events = [];
    this.subEvents$ = this._eventsService.getEvents(eventState).subscribe({
      next: response => {
        this.subscriptionList.add(this.subEvents$);
        if (response) {
          this.events = response.data ? response.data : response;
          this.showMultipleEventsMessage = response.repeatUpcomingEvents;
          this.eventState = eventState;
          this.events.forEach(el => {
            const { name, icon_class } = this._eventRequestOverviewService.getEventIconConfig(el.mainEventType);
            el.iconName = name;
            el.iconClass = eventState === 'upcoming' ? icon_class : 'icon_inactive';
            el.mainEventState = eventState;
            if (el.mainEventType === EventRequestTypeEnum.UNSPECIFIED_EVENT) {
              el.mainEventType = 'EVENT';
            }
          });
          this.loadingEvents = false;
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      },
      error: err => {
        console.error('Events could not be retrieved: ', err?.toString() || JSON.stringify(err, null, 2));
        this.loadingEvents = false;
        this._store.dispatch(appActions.DecrementLoadingCount());
      }
    });
  }

  setEmptyState(eventState: string) {
    switch (eventState) {
      case 'upcoming':
        this.emptyMessageConfig = {
          image: '../../../../../assets/images/events-empty-state.png',
          title: 'Submit a request to get started!',
          message: 'Fill out a simple form to start finding the best suppliers for your event.',
          class: 'empty-state',
          actions: [
            {
              icon: 'ate-plus-v3',
              class: 'btn-action btn-action-md btn-primary',
              text: 'New Service Request',
              trigger: EmptyMessageActionTriggers.NEW_REQUEST
            }
          ]
        };
        break;
      case 'past':
        this.emptyMessageConfig = {
          icon: {
            name: 'ate-calendar-next-month',
            class: 'tw-size-[100px]'
          },
          title: 'No past events',
          message: 'All your past events will be shown here.',
          class: 'empty-state'
        };
        break;
    }
  }

  receiveActionTrigger(event) {
    if (event === EmptyMessageActionTriggers.NEW_REQUEST) {
      this.openRequestQuotes();
    }
  }

  openRequestQuotes(): void {
    // this.window.open(environment.requestQuotesUrl, '_self');
    this._router.navigateByUrl('new-request');
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
