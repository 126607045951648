import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IUserNotifications } from './notifications.interface';
import { catchError, map, of } from 'rxjs';
import { IUserNotification } from './notification.interface';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarConfig } from '@angular/material/snack-bar';

export class NotificationModel {
  type: NotificationTypes;
  title?: string;
  message?: string;
  action?: string;
  class?: string;
  duration?: number;
}

export enum NotificationTypes {
  INFO = 'snackbar-info',
  SUCCESS = 'snackbar-success',
  ERROR = 'snackbar-error',
  WARNING = 'snackbar-warning'
}

@Injectable()
export class NotificationsService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private _http: HttpClient,
    private _snackBar: MatSnackBar
  ) {}

  getNotifications() {
    return this._http
      .get<IUserNotifications>(`${environment.apiUrl}/org/users/notifications`, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        }
      })
      .pipe(
        map((response: IUserNotifications) => response.data),
        catchError(err => {
          console.error(err?.toString() || JSON.stringify(err, null, 2));
          // Treat as if the user has no notifications.
          return of([] as IUserNotification[]);
        })
      );
  }

  /**
   * Trigger a notification snack bar based on configuration provided
   * @param notification
   */
  openSnackBar(notification: NotificationModel) {
    const snackBarConfig: MatSnackBarConfig = {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: notification.type,
      duration: notification.duration ? notification.duration : 3000
    };
    this._snackBar.open(notification.message, notification.action, snackBarConfig);
  }
}
