import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

import { EventRequestSingleType, FormResponse, IFormResponse } from '../../../../shared/services/event-request/event-request.type';
import { EventRequestService } from '../../../../shared/services/event-request/event-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { filter, first, Observable, Subject, takeUntil } from 'rxjs';
import { appActions } from '../../../../store/actions/app.actions';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { AppState } from '../../../../store/reducers/app.reducer';
import { EmptyMessageConfig } from '../../../../shared/models/misc';
import { selectEvent } from '../../../../store/selectors/event.selectors';
import { EventRequestStatusEnum } from '../../../../shared/services/event-request/event-request-status.enum';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { eventActions } from '../../../../store/actions/event.actions';

@Component({
  selector: 'app-event-request-details',
  templateUrl: './event-request-details.component.html',
  styleUrls: ['./event-request-details.component.scss']
})
export class EventRequestDetailsComponent implements OnInit, OnDestroy {
  apiKey = environment.googleMapsApiKey;
  emptyMessageConfig: EmptyMessageConfig;
  isLoading: Observable<boolean>;
  eventRequest: EventRequestSingleType;
  eventDescription: string;
  isError: boolean = false;
  showEditRequest = false;
  formResponses: FormResponse[];
  eventStartDate: string;
  eventStartTime: string;
  eventDuration: string;
  private _eventRequestUuid;
  private _destroy$ = new Subject();

  constructor(
    private _eventRequestService: EventRequestService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<AppState>,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService
  ) {}

  ngOnInit(): void {
    this.isLoading = this._store.select(selectApp.getIsLoading);
    this.subscribeToEvent();
    this.emptyMessageConfig = {
      icon: {
        name: 'ate-alert-v2',
        class: 'icon_alert'
      },
      title: 'Unknown Error: Something went wrong'
    };
  }

  goBack(): void {
    this._router.navigate(['..'], { relativeTo: this._route });
  }

  editRequest(): void {}

  subscribeToEvent(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.isError = false;
    this._store
      .select(selectEvent.getEvent)
      .pipe(
        takeUntil(this._destroy$),
        filter(value => value !== undefined)
      )
      .subscribe({
        next: data => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          if (data) {
            this.eventRequest = data;
            const { startDate, startTime, duration, description, eventOrganiserStatus, uuid } = this.eventRequest.event;

            this.eventDescription = description;
            this.eventStartDate = this.processStartDate(startDate);
            this.eventStartTime = this.processStartTime(startTime);
            this.eventDuration = this.processDuration(duration);
            this._eventRequestUuid = uuid;
            this.formResponses = this.handleFormResponse(this.eventRequest.responses);
            // Only show the edit request button if we are in a pre-quoted state. Changing the
            // request after quotes have been submitted would likely lead to irritated suppliers and
            // a higher refund rate.
            this.showEditRequest =
              eventOrganiserStatus === EventRequestStatusEnum.AWAITING_QUOTES || eventOrganiserStatus === EventRequestStatusEnum.AWAITING_APPROVAL;
          }
        },
        error: err => {
          this.isError = true;
          console.error(err?.toString() || JSON.stringify(err, null, 2));
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      });
  }

  refreshRequestOnFormClose() {
    this._enhancedFormsLauncherService
      .onFormClose()
      .pipe(first())
      .subscribe({
        next: () => {
          const { paramMap } = this._route.snapshot;

          this._store.dispatch(
            eventActions.GetEvent({
              payload: {
                eventUuid: paramMap.get('eventUuid'),
                eventRequestUuid: paramMap.get('eventRequestUuid')
              }
            })
          );
        },
        error: err => {
          console.error(err?.toString() || JSON.stringify(err, null, 2));
        }
      });
  }

  openEditRequestForm() {
    if (!this._eventRequestUuid) {
      console.log(this._eventRequestUuid, 'test');
      return;
    }
    this.refreshRequestOnFormClose();

    this._eventRequestService
      .getRequestResponses(this._eventRequestUuid)
      .pipe(first())
      .subscribe(({ response, formUuid }) => {
        if (!response) {
          console.error('Could not load previous responses');
        }

        this._enhancedFormsLauncherService.launchForm({
          formUuid,
          eventUuid: this.eventRequest.event.uuid,
          mainEventUuid: this.eventRequest.event.mainEventUuid,
          model: response,
          formType: 'simplified-new-event',
          editMode: true,
          forceFormInit: true
        });
      });
  }

  /**
   * Returns the time as an internationalised string.
   * @param startTime The string representation of the time
   */
  private processStartTime(startTime: string): string {
    if (!startTime) {
      return '';
    }
    const time = new Date(startTime);
    const intlTime = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(time);

    return intlTime;
  }

  /**
   * Returns the time as an internationalised string. (e.g. 2 hours 20 minutes)
   * @param duration The number of seconds
   */
  private processDuration(duration: number): string {
    if (!duration) {
      return '';
    }
    return formatDuration(intervalToDuration({ start: 0, end: duration * 1000 }), { format: ['days', 'hours', 'minutes'] });
  }

  /**
   * Returns the date as an internationalised string.
   * @param startDate The string representation of the date
   */
  private processStartDate(startDate: string): string {
    const date = new Date(startDate);

    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'medium'
    }).format(date);
  }

  /**
   * Parses the form response so that we can display the responses easily in
   * the view.
   *
   * @param formResponse The form response object
   */
  private handleFormResponse(formResponse: IFormResponse): FormResponse[] {
    return Object.values(formResponse).reduce<FormResponse[]>((responses, answer) => {
      if (!answer.value) {
        return;
      }

      return [...responses, { ...answer, isArray: Array.isArray(answer.value) }];
    }, []);
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
