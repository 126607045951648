<!-- ******************************* -->
<!-- ******* REQUESTS HEADER ******* -->
<!-- ******************************* -->
<div
  [style.background-image]="isMobile && eventRequest?.primaryServiceImageURI ? 'url(' + eventRequest.primaryServiceImageURI + ')' : ''"
  [style.background-blend-mode]="grayscaleServiceImage ? 'luminosity' : 'unset'"
  class="tw-relative tw-block tw-flex-auto tw-flex-row tw-gap-x-5 tw-overflow-hidden tw-border-0 tw-border-b tw-border-solid tw-border-slate-300 tw-bg-white tw-bg-cover tw-bg-center lg:tw-flex lg:tw-p-6">
  <div class="tw-flex tw-px-5 tw-py-4 lg:tw-p-0">
    <ate-icon (click)="goBack()" name="chevron-left-v2" class="btn-icon"></ate-icon>
    <ate-icon *ngIf="isMobile" class="btn-icon tw-ml-auto" name="more-v3" (click)="openBottomSheet()"></ate-icon>
  </div>
  <img
    *ngIf="!isMobile"
    [src]="eventRequest?.primaryServiceImageURI"
    [ngClass]="{ greyScale: grayscaleServiceImage }"
    alt="Request image"
    class="tw-size-[112px] tw-rounded-lg"
    #requestPicture />
  <div *ngIf="!isMobile" class="tw-absolute tw-right-[50px] tw-top-[-10px] tw-z-[1] tw-overflow-hidden">
    <ate-icon *ngIf="iconName" class="tw-h-[190px] tw-w-[190px]" [ngClass]="iconColour" [name]="iconName"></ate-icon>
  </div>

  <div
    class="tw-relative tw-flex tw-min-w-0 tw-flex-auto tw-flex-col tw-items-start tw-rounded-t-2xl tw-bg-white tw-px-5 tw-py-6 lg:tw-bg-transparent lg:tw-p-0">
    <div class="tw-z-[2] tw-w-full">
      <div class="tw-mb-4 tw-flex-auto">
        <h2 class="truncate tw-mb-1 tw-max-w-full tw-text-xl tw-font-bold tw-text-slate-800">{{ eventRequest?.primaryServiceTitle }}</h2>
        <p class="truncate tw-mb-0 tw-max-w-full tw-text-sm tw-font-semibold tw-text-slate-600">
          {{ eventRequest?.event?.eventType | eventType }} • {{ eventRequest?.event?.startDate | date: 'd MMM y' }} •
          {{ eventRequest?.event?.geocode?.fullAddress }}
        </p>
      </div>
      <div class="tw-flex-grow-0">
        <app-status-indicator [statusConfig]="status"></app-status-indicator>
      </div>
    </div>
    <div *ngIf="isMobile" class="tw-absolute tw-bottom-6 tw-right-3 tw-z-[1] tw-h-24 tw-w-24 tw-overflow-hidden">
      <ate-icon *ngIf="iconName" class="tw-h-24 tw-w-24" [ngClass]="iconColour" [name]="iconName"></ate-icon>
    </div>
  </div>
  <div
    *ngIf="!isMobile"
    class="tw-basis-34 tw-z-[2] tw-flex tw-w-fit tw-shrink-0 tw-items-start tw-justify-end lg:tw-right-6 lg:tw-top-6 lg:tw-order-1">
    <div class="menu-container">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="btn-menu !tw-rounded-lg tw-bg-white tw-px-4 tw-py-2 tw-text-base !tw-text-slate-600"
        disableRipple>
        Manage Request
      </button>
      <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
        <button mat-menu-item (click)="openEventRequestDetails()" disableRipple>
          <ate-icon class="!tw-h-6 !tw-w-6" [name]="iconName"></ate-icon>
          <span>View Request Details</span>
        </button>
        <button mat-menu-item (click)="openChangeParentEventModal()" disableRipple *ngIf="events && events.length > 0">
          <ate-icon class="!tw-h-6 !tw-w-6" name="merge"></ate-icon>
          <span>Change Parent Event</span>
        </button>
        <button mat-menu-item (click)="openCloseEventRequest()" disableRipple *ngIf="config?.closeServiceRequestButton">
          <ate-icon name="circle-cross-outline-v3" class="!tw-h-6 !tw-w-6 tw-text-red-700"></ate-icon>
          <span class="tw-text-red-700">Cancel Service Request</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<!-- ******************************* -->
<!-- ******** REQUESTS BODY ******** -->
<!-- ******************************* -->

<div class="tiles-container tw-px-5 tw-pb-6 tw-pt-5 md:tw-px-8 md:tw-py-6">
  <!-- ******** STATUS INFORMATION SECTION ******** -->
  <app-draft-request-information-box
    [eventUuid]="eventRequest?.event?.uuid"
    [mainEventUuid]="eventRequest?.mainEventUuid"
    [eventRequestUuid]="eventRequestUuid"
    [formUuid]="eventRequest?.event?.formUuid"
    *ngIf="eventRequest?.event?.eventOrganiserStatus === 'DRAFT'">
  </app-draft-request-information-box>

  <app-information-box *ngIf="!informationConfig?.showNewRequestButton && informationConfig">
    <p class="tw-mb-0 tw-text-sm lg:tw-text-base" [innerHTML]="informationConfig?.body"></p>
  </app-information-box>

  <app-new-request-information-box
    *ngIf="informationConfig?.showNewRequestButton && eventRequest?.event?.eventOrganiserStatus !== 'DRAFT' && informationConfig"
    [body]="informationConfig?.body"
    [title]="informationConfig?.title"
    [buttonText]="informationConfig?.buttonText">
  </app-new-request-information-box>

  <!-- ******** QUOTES & BOOKINGS ******** -->
  <div class="tw-flex tw-flex-auto tw-flex-col tw-gap-5" *ngIf="showQuotes">
    <div class="tw-flex tw-flex-auto tw-flex-row tw-items-center tw-justify-between">
      <h2 class="tw-text-2xl">Suppliers</h2>
    </div>

    <div class="animated fadeIn tw-flex tw-flex-auto tw-flex-row tw-items-center tw-justify-between" *ngIf="quoteFilters">
      <app-filter-bar [filters]="quoteFilters" (selectedFilter)="receiveEmittedFilter($event)"></app-filter-bar>
    </div>

    <!-- *** QUOTES *** -->
    <div *ngIf="viewType === 'quotes'" class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3">
      <app-quote-tile *ngFor="let quote of quotes; let i = index" [tabIndex]="i + 1" [quote]="quote" (click)="goToQuote(quote)"></app-quote-tile>
    </div>

    <!-- *** BOOKINGS *** -->
    <div *ngIf="viewType === 'booked'" class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3">
      <app-quote-tile
        *ngFor="let booking of bookings; let i = index"
        [tabIndex]="i + 1"
        [quote]="booking"
        (click)="goToQuote(booking)"></app-quote-tile>
    </div>

    <!-- *** INVITED *** -->
    <div *ngIf="viewType === 'invited'" class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3">
      <app-invite-tile
        *ngFor="let invite of invited; let i = index"
        [tabIndex]="i + 1"
        [invite]="invite"
        [class.declined]="invite.isDeclined"
        (click)="!invite.isDeclined && getPublicListing(invite, 'invited')"></app-invite-tile>
    </div>

    <!-- *** INTERESTED *** -->
    <div *ngIf="viewType === 'interested'" class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3">
      <app-interested-tile
        *ngFor="let item of interested; let i = index"
        [tabIndex]="i + 1"
        [eventRequestUuid]="eventRequestUuid"
        [interest]="item"
        [suggestedSupplierAction]="suggestedSupplierAction"
        (supplierSkipped)="receiveListingUpdate()"
        (supplierInvited)="receiveListingUpdate()"
        (click)="getPublicListing(item, 'interested')"></app-interested-tile>
    </div>

    <!-- *** SUGGESTED *** -->
    <div *ngIf="viewType === 'suggested'">
      <div class="tw-mb-5 tw-flex tw-flex-auto tw-items-center tw-gap-x-3 tw-rounded-lg tw-bg-blue-100 tw-p-4">
        <ate-icon name="ate-info-v2" class="tw-size-6 tw-shrink-0 tw-font-bold"></ate-icon>
        <p class="tw-mb-0">
          <span>We've found some great suppliers for your event. If any catch your eye, invite them to quote for a</span>
          <span>&nbsp;</span>
          <span class="tw-font-bold">30% quicker response.</span>
        </p>
      </div>

      <div class="tw-grid tw-gap-5 md:tw-grid-cols-2 xl:tw-grid-cols-3">
        <app-suggested-tile
          *ngFor="let item of suggested; let i = index"
          [tabIndex]="i + 1"
          [eventRequestUuid]="eventRequestUuid"
          [suggestedInvite]="item"
          [invitesSent]="invited?.length || 0"
          [suggestedSupplierAction]="suggestedSupplierAction"
          (supplierSkipped)="receiveListingUpdate()"
          (supplierInvited)="receiveListingUpdate()"
          (click)="getPublicListing(item, 'suggested')"></app-suggested-tile>
      </div>
      @if (canLoadMoreSuppliers) {
        <ate-button-control
          class="tw-ml-auto tw-mr-auto tw-mt-6 tw-rounded-lg lg:tw-flex lg:tw-w-fit"
          (click)="getInvites(false, true)"
          color="secondary">
          Show more suppliers
        </ate-button-control>
      }
    </div>
    <app-utility-message
      [config]="emptyNotification"
      class="animated fadeIn !tw-w-auto tw-overflow-hidden tw-rounded-xl tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-5 tw-py-20"
      *ngIf="
        viewType === 'no-quotes' ||
        viewType === 'no-bookings' ||
        viewType === 'no-invited' ||
        viewType === 'no-interested' ||
        viewType === 'no-suggested'
      ">
    </app-utility-message>
  </div>

  <!-- ******** PROGRESS FOR AWAITING_APPROVAL & AWAITING_QUOTES ******** -->
  <app-progress
    *ngIf="viewType === 'progress'"
    class="tw-mx-auto tw-my-6 tw-block"
    [status]="eventRequest?.event?.eventOrganiserStatus"></app-progress>

  <app-upsells *ngIf="eventRequest" [eventRequest]="eventRequest" [upsells]="eventRequest.upsells" [eventUpsells]="false"></app-upsells>
</div>

<!-- This is for displaying the modal -->
<router-outlet name="modalOutlet"></router-outlet>
