import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServiceListItem } from './service-list-item.interface';
import { first, map, Observable, of, shareReplay } from 'rxjs';

@Injectable()
export class ServiceListService {
  private serviceList$: Observable<ServiceListItem[]>;

  constructor(
    private _http: HttpClient,
    @Inject('environment') private environment
  ) {}

  getServiceList() {
    if (!this.serviceList$) {
      this.serviceList$ = this._http
        .get<ServiceListItem[]>(`${this.environment.apiUrl}/org/service/list`, {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json'
          }
        })
        .pipe(shareReplay(1));
    }
    return this.serviceList$;
  }

  /**
   * Checks if a form is enabled for V3. Ignores the V1Form property, as this is used
   * for the public site only.
   *
   * @param formUuid The formUuid of the service to check.
   * @returns true if the form is enabled for V3, false otherwise.
   */
  isV3Form(formUuid: string) {
    if (!formUuid) {
      console.error('no formUuid provided when checking if form is v3');
      return of(false);
    }

    return this.getServiceList().pipe(
      first(),
      map(serviceList => {
        return !!serviceList.find(service => service.formUuid === formUuid);
      })
    );
  }
}
