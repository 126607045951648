import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonService } from '../../../../shared/services/common/common.service';
import { Subscription } from 'rxjs';
import { DialogData, DialogType } from '../../../../shared/models/dialogs';

export class ListingAction {
  listingUuid: string;
  action: string;
}

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrl: './supplier.component.scss'
})
export class SupplierComponent implements OnInit {
  hideFooter: boolean;
  innerHeight: number;
  innerWidth: number;
  subGallery$: Subscription;
  subscriptionList: Subscription = new Subscription();

  @ViewChild('supplierDialogFooter', { static: true }) footer: ElementRef;

  @Input() dialogData: DialogData;
  @Output() selectedAction: EventEmitter<ListingAction> = new EventEmitter<ListingAction>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  constructor(private _commonService: CommonService) {
    this.onResize();
  }

  ngOnInit() {
    this.setFooter();
  }

  setFooter() {
    this.subGallery$ = this._commonService.galleryState.subscribe({
      next: response => {
        if (this.dialogData.type === DialogType.INVITED) {
          this.hideFooter = true;
        } else {
          this.hideFooter = response;
        }
      },
      error: err => {
        console.error(err?.toString() || JSON.stringify(err, null, 2));
      }
    });
  }

  emitAction(action: string) {
    const supplierAction: ListingAction = {
      listingUuid: this.dialogData?.record?.inviteData?.uuid,
      action: action
    };
    this.selectedAction.emit(supplierAction);
  }
}
