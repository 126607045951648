import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortname' })
export class ShortNamePipeline implements PipeTransform {
  transform(value: { firstName: string; lastName: string } | string): string {
    try {
      const toConvert =
        typeof value === 'string'
          ? {
              firstName: value.split(' ').length ? value.split(' ')[0] : value,
              lastName: value.split(' ').length > 1 ? value.split(' ')[1] : ''
            }
          : value;

      const first = toConvert?.firstName ? toConvert?.firstName[0] : '';
      const last = toConvert?.lastName ? toConvert?.lastName[0] : '';

      return (first + last).toUpperCase();
    } catch (err) {
      console.error(err?.toString() || JSON.stringify(err, null, 2));
      return '';
    }
  }
}
