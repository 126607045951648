import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { MaterialModule } from '../../../shared/material/material.module';
import { TagsDialogComponent } from '../../../shared/components/dialogs/tags-dialog/tags-dialog.component';

@Component({
  selector: 'app-info-tag-list',
  templateUrl: './info-tag-list.component.html',
  styleUrls: ['./info-tag-list.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule]
})
export class InfoTagListComponent implements OnChanges {
  innerHeight: number;
  innerWidth: number;
  maxServices = 2;
  reducedTags: string[];
  subDialog$: Subscription;
  subscriptionList = new Subscription();

  @ViewChild('servicesElement') servicesElement: ElementRef;
  @Input() tags: string[];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  constructor(
    public dialog: MatDialog,
    private sso: ScrollStrategyOptions
  ) {
    this.onResize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tags) {
      this.reducedTags = this.reduceTags(this.tags, this.maxServices);
    }
  }

  /**
   * Generate a list of reducedTags from tags passed by the parent, by setting max length and adding `button tag`
   * if max length exceeds the fromLength
   * @param tags
   * @param fromLength
   */
  reduceTags(tags: string[], fromLength: number) {
    let elementsToSplice = 0;
    let servicesText: string;
    const reducedTags: string[] = Array.from(tags);
    if (reducedTags.length > fromLength) {
      elementsToSplice = reducedTags.length - fromLength;
      servicesText = elementsToSplice === 1 ? 'service' : 'services';
      reducedTags.splice(fromLength - 1, elementsToSplice);
      reducedTags.push(`+ ${elementsToSplice} ${servicesText}`);
    }
    return reducedTags;
  }

  /**
   * Show dialog containing all service tags
   */
  showAllServices() {
    const dialogRef = this.dialog.open(TagsDialogComponent, {
      width: '500px',
      height: 'auto',
      maxWidth: '96vw',
      maxHeight: '550px',
      hasBackdrop: true,
      panelClass: 'confirmation-dialog',
      scrollStrategy: this.sso.noop(),
      data: {
        tags: this.tags
      }
    });
    this.subDialog$ = dialogRef.afterClosed().subscribe({
      next: () => {
        this.subscriptionList.add(this.subDialog$);
      },
      error: err => {
        console.error(err?.toString() || JSON.stringify(err, null, 2));
      }
    });
  }
}
