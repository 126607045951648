<ng-template #button>
  @if (isV3Form) {
    <ate-button-control
      color="secondary"
      [state]="_isLoading ? 'disabled' : ''"
      (click)="openEditRequestForm()"
      class="!tw-mt-0 tw-flex-[1_0_0] tw-gap-2 tw-whitespace-nowrap"
      >Edit & Submit
    </ate-button-control>
  }
  <ate-button-control
    [state]="_isLoading ? 'disabled' : ''"
    (click)="getQuotesNow()"
    class="gradient-button !tw-mt-0 tw-flex-[1_0_0] tw-gap-2 tw-whitespace-nowrap">
    <ate-icon class="tw-size-6 tw-animate-spin" name="loading-spinner-v3" *ngIf="_isLoading"></ate-icon> Get Quotes Now
  </ate-button-control>
</ng-template>

<app-information-box [button]="button" colour="blue">
  <p class="tw-mb-0 tw-text-sm lg:tw-text-base">Send your request now to get quotes from suppliers for this request</p>
</app-information-box>
