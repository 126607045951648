<div class="tw-flex tw-flex-auto tw-flex-col" *ngIf="data.tags">
  <div class="tw-flex tw-border-0 tw-border-b tw-border-solid tw-border-slate-300 tw-p-3">
    <div class="tw-flex tw-w-5"></div>
    <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
      <h2>Services</h2>
    </div>
    <ate-icon
      (click)="closeDialog(null)"
      name="ate-close-v2"
      class="link-item tw-ml-auto tw-block tw-size-5 tw-text-slate-500 lg:tw-size-6"></ate-icon>
  </div>
  <section class="tw-mx-6 tw-my-5 lg:tw-my-6">
    <div class="animated fadeIn tw-flex tw-flex-auto tw-flex-row tw-flex-wrap tw-items-center tw-justify-center tw-gap-2">
      <div
        class="tw-flex tw-h-[20px] tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-slate-300 tw-bg-slate-50 tw-px-3 tw-py-1"
        *ngFor="let tag of data.tags; let last = last">
        <p class="tw-m-0 tw-text-xs tw-font-semibold tw-text-gray-800 xl:tw-text-base">{{ tag }}</p>
      </div>
    </div>
  </section>
</div>
