/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, first, fromEvent } from 'rxjs';
import { EventType } from '../event-request/event-request.type';
import { FormModel } from './form-model.interface';
import { AuthService } from '../auth/auth.service';
import { Store } from '@ngrx/store';
import { userQuery } from '../../../store/selectors/user.selectors';
import { IUserDetails } from '../user/user-details-interface';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { EnhancedFormClose } from '../../enums/enhanced-form-close.enum';
declare global {
  interface Window {
    ateV2OpenForm: (formLaunchConfig: { [key: string]: any }, forceFormInit: boolean) => void;
  }
}

@Injectable()
export class EnhancedFormsLauncherService {
  private readonly localStorageAuthKey = 'enhancedFormsfirebaseAuthToken';

  constructor(
    @Inject('environment') private readonly environment,
    private readonly _authService: AuthService,
    private readonly _store: Store,
    private readonly _cookieService: CookieService,
    private readonly _route: ActivatedRoute
  ) {}
  /**
   * Launch the Enhanced Form in a dialog on the current page.
   *
   * @param param0 Configuration for the Enhanced Forms
   */
  launchForm({
    formUuid = '',
    eventUuid = '',
    mainEventUuid = '',
    userUuid = '',
    model,
    postcode = '',
    formType,
    editMode = false,
    forceFormInit = false
  }: {
    formUuid: string;
    eventUuid?: string;
    mainEventUuid?: string;
    userUuid?: string;
    model?: { [key: string]: any };
    postcode?: string;
    formType?: 'simplified' | 'simplified-new-event';
    /**
     * Updates an existing form, rather than submitting a new request
     */
    editMode?: boolean;
    forceFormInit?: boolean;
  }) {
    this._store
      .select(userQuery.getUserDetails)
      .pipe(first())
      .subscribe({
        next: userDetails => {
          const enhancedModel = {
            ...model,
            ...this.getModelFromUserDetails(userDetails)
          };

          this._authService
            .getAuthToken$()
            .pipe(
              first(),
              filter(token => !!token)
            )
            .subscribe({
              next: token => {
                this.saveAuthTokenToLocalStorage(token);

                this._route.queryParams.pipe(first()).subscribe(params => {
                  const allowedParams = {
                    utm_source: params['utm_source'],
                    utm_medium: params['utm_medium'],
                    utm_term: params['utm_term'],
                    ate_adgroup: params['ate_adgroup'],
                    ate_campaign: params['ate_campaign'],
                    gclid: params['gclid'],
                    utm_campaign: params['utm_campaign'],
                    gclsrc: params['gclsrc'],
                    msclkid: params['msclkid'],
                    utmcontent: params['utmcontent'],
                    gad_source: params['gad_source']
                  };

                  const filteredParams = Object.fromEntries(Object.entries(allowedParams).filter(([, value]) => value !== undefined));

                  const requestBody = Object.keys(filteredParams).length > 0 ? filteredParams : null;

                  Object.entries(filteredParams).forEach(([key, value]) => {
                    this._cookieService.set(key, value, 30, '/'); // 30-day expiry, path "/"
                  });
                  window.ateV2OpenForm(
                    {
                      formUuid: this.getFormName(mainEventUuid, formUuid, model, formType),
                      googlePlacesApiKey: this.environment.googleMapsApiKey,
                      eventUuid,
                      model: enhancedModel, // Pass the enhanced model
                      mainEventUuid,
                      userUuid,
                      postcode,
                      editMode,
                      ...(requestBody && { requestBody })
                    },
                    forceFormInit
                  );
                });
              },
              error: console.error
            });
        },
        error: err => {
          console.error('Error fetching user details:', err?.toString() || JSON.stringify(err, null, 2));
        }
      });
  }

  onFormClose(): Observable<CustomEvent<EnhancedFormClose>> {
    return fromEvent<CustomEvent<EnhancedFormClose>>(window, 'onExitForm');
  }

  getModelFromUserDetails(user: IUserDetails): FormModel {
    return {
      ...(user?.lastUsedPhoneNumber && { field_event_phone: user?.lastUsedPhoneNumber ?? '' }),
      ...(user?.firstName && { field_event_first_name: user?.firstName ?? '' }),
      ...(user?.lastName && { field_event_first_surname: user?.lastName ?? '' })
    };
  }

  /**
   * Constructs an object that can be used to pre-populate the fields of the enhanced forms.
   * Can be expanded with the other fields found in the form definitions.
   *
   * @param eventRequest The event object that we can use to populate the model.
   */
  getModelFromEvent(event: EventType): FormModel {
    return { ...(event.description && { field_event_body: event.description ?? '' }) };
  }

  /**
   * Adds a suffix to the form UUID based on the parameters passed in.
   *
   * @param mainEventUuid The main event UUID - only provided if an event exists
   * @param formUuid The uuid of the form
   * @param model The previous answers for an initial form to be used for supplementary forms.
   *
   * @returns The full form UUID with any suffix included.
   */
  private getFormName(mainEventUuid: string, formUuid: string, model, formType?: 'simplified' | 'simplified-new-event'): string {
    // Override the form name logic
    if (formType) {
      return `${formUuid}-${formType}`;
    }

    // It must be a supplementary form because we already have the data.
    if (model) {
      return formUuid;
    }

    // It's a request for either an existing event or a new event.
    return `${formUuid}${mainEventUuid ? '-simplified' : '-simplified-new-event'}`;
  }

  /**
   * TODO: Automatically refresh the token in local storage on expiry.
   * @param token The auth token from Firebase.
   */
  private saveAuthTokenToLocalStorage(token: string) {
    console.log(`Auth token set at ${Date.now()}`);
    window.localStorage.setItem(this.localStorageAuthKey, token);
  }
}
