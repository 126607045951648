import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { QuoteService } from '../../../services/quote.service';
import { CommonModule } from '@angular/common';
import { QuoteModel } from '../../../../../shared/models/quotes';
import { TileBaseComponent } from '../tile-base/tile-base.component';
import { EnhancedStatusPillComponent } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill.component';
import { EnhancedStatusPillConfig } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill-config.interface';
import { QuoteStatusConfig } from './quote-tile-config';
import { QuoteStatus } from '../../../../../api';

@Component({
  selector: 'app-quote-tile',
  templateUrl: './quote-tile.component.html',
  styleUrls: ['./quote-tile.component.scss'],
  standalone: true,
  imports: [CommonModule, TileBaseComponent, EnhancedStatusPillComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QuoteTileComponent implements OnChanges, OnInit {
  @Input() quote: QuoteModel;

  statusReason = null;
  statusPillConfig: EnhancedStatusPillConfig;
  isDeclined: boolean;

  constructor(
    private _quoteService: QuoteService,
    private _router: Router
  ) {}

  ngOnInit() {
    this.processQuote();
  }

  /**
   * Make sure that we update the template values if the quote object changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quote.currentValue) {
      this.processQuote();
    }
  }

  goToQuote(): void {
    if (!this.statusReason) {
      this._router.navigate(['/quote/', this.quote.uuid]);
    }
  }

  private processQuote() {
    this.statusPillConfig = this.getStatusPillConfig();
    this.isDeclined = [QuoteStatus.REJECTED_FUTURE, QuoteStatus.REJECTED_PAST].includes(this.quote?.quoteOrganiserStatus);
    this.processReason();
  }

  private getStatusPillConfig(): EnhancedStatusPillConfig {
    return { ...QuoteStatusConfig[this.quote?.quoteOrganiserStatus], rightSideText: this.quote?.quotePrice ? `£${this.quote?.quotePrice}` : '' };
  }

  private processReason(): void {
    if (this.quote?.reason) {
      this.statusReason = this._quoteService.processSupplierDelineReason(this.quote?.reason);
    }
  }
}
