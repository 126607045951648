import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { WindowService } from '../../../../shared/services/window/window.service';
import { IMainEventData } from '../../../../shared/models/events';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from '../../../../shared/services/events/events.service';
import { EventRequestOverviewService } from '../../../../shared/services/event-request-overview/event-request-overview.service';
import { appActions } from '../../../../store/actions/app.actions';
import { Store } from '@ngrx/store';
import { EventRequestTypeEnum } from '../../../../shared/services/event-request/event-request-type.enum';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.scss'
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  event: IMainEventData;
  iconColour: string = 'tw-text-slate-50';
  innerHeight: number;
  innerWidth: number;
  isMobile: boolean;
  isSmallMobile: boolean;
  maxLengthLocation: number;
  subEvent$: Subscription;
  subRoute$: Subscription;
  subscriptionList: Subscription = new Subscription();
  newRequestSectionOpen = false;

  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.isMobile = this._windowService.isMobile();
    this.isSmallMobile = this._windowService.isSmallMobile();

    if (this.innerWidth < 992) {
      this.maxLengthLocation = 30;
    } else if (this.innerWidth < 1280) {
      this.maxLengthLocation = 60;
    } else {
      this.maxLengthLocation = 100;
    }
  }

  constructor(
    private _windowService: WindowService,
    private _activatedRoute: ActivatedRoute,
    private _eventsService: EventsService,
    private _router: Router,
    private _eventRequestOverviewService: EventRequestOverviewService,
    private _store: Store
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.getRoute();
    // Only open the new request section if we're on the new-request route.
    if (this._router.url.includes('add-request')) {
      this.newRequestSectionOpen = true;
    }
  }

  getRoute() {
    this.subRoute$ = this._activatedRoute.paramMap.subscribe({
      next: response => {
        this.subscriptionList.add(this.subRoute$);
        this.getEvent(response.get('eventState'), response.get('eventUuid'));
      },
      error: err => console.error(err?.toString() || JSON.stringify(err, null, 2))
    });
  }

  getEvent(eventState: string, eventUuid: string) {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.subEvent$ = this._eventsService.getEvent(eventUuid).subscribe({
      next: response => {
        if (response) {
          this.event = response;
          const { name, icon_class } = this._eventRequestOverviewService.getEventIconConfig(this.event.mainEventType);
          this.event.iconName = name;
          this.event.mainEventState = eventState;
          this.event.iconClass = eventState === 'upcoming' ? icon_class : 'icon_inactive';
          this.setIcon(this.event);
          if (response.mainEventType === EventRequestTypeEnum.UNSPECIFIED_EVENT) {
            this.event.mainEventType = 'EVENT';
          }
        }
        this._store.dispatch(appActions.DecrementLoadingCount());
      },
      error: err => {
        console.error(`Event ${eventUuid} could not be retrieved`, err?.toString() || JSON.stringify(err, null, 2));
        this._store.dispatch(appActions.DecrementLoadingCount());
      }
    });
  }

  setIcon(event: IMainEventData) {
    const { colour } = this._eventRequestOverviewService.getIconConfig(event.mainEventType);
    if (event.mainEventState === 'upcoming') {
      this.iconColour = colour;
    } else {
      this.iconColour = 'tw-text-slate-100';
    }
  }

  goBack(): void {
    this._router.navigateByUrl(`events/${this.event.mainEventState}`);
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this.event = null;
  }
}
